<template>
	<b-modal id="modal-primary" hide-footer modal-class="modal-primary" centered :title="title">
		<b-card-text v-if="duration && !modalLoading">
			<div class="row">
				<div class="col-12">
					<table class="mt-1 mt-xl-0 w-100">
						<tr>
							<th class="pb-50 d-flex align-items-center">
								<feather-icon icon="CodepenIcon" size="14" class="mr-75" />
								<span class="font-weight-bold">Channel</span></th>
							<td class="pb-50 text-capitalize"> {{duration.text}} </td>
						</tr>
						<tr>
							<th class="pb-50 d-flex align-items-center">
								<feather-icon icon="CalendarIcon" size="14" class="mr-75" />
								<span class="font-weight-bold">Start Date</span></th>
							<td class="pb-50 text-capitalize"> {{duration.start}} </td>
						</tr>
						<tr>
							<th class="pb-50 d-flex align-items-center">
								<feather-icon icon="CalendarIcon" size="14" class="mr-75" />
								<span class="font-weight-bold">End Date</span></th>
							<td class="pb-50"> {{duration.end}} </td>
						</tr>
						<tr>
							<th class="pb-50 d-flex align-items-center">
								<feather-icon icon="CalendarIcon" size="14" class="mr-75" />
								<span class="font-weight-bold">Time Spent</span></th>
							<td class="pb-50"> {{duration.minutes}} min </td>
						</tr>
						<tr>
							<th>
								<feather-icon icon="TypeIcon" size="14" class="mr-75" />
								<span class="font-weight-bold">Channel Type</span></th>
							<td> {{duration.work ? 'Work':'Fun'}} </td>
						</tr>
					</table>
					<div class="mt-1 text-left" v-if="channelDetail">
						<div class="font-weight-bold mb-1">
							Who joined the channel ({{channelDetail.length}})
						</div>
						<div id="channel_users">
							<div v-for="(user,index) in channelDetail" :key="index"
								class="employee-task d-flex justify-content-between align-items-center mb-1">
								<b-media no-body>
									<b-media-aside class="mr-75">
										<b-avatar size="36" :src="user.image" :text="avatarText(user.name)"
											:style="duration.id == user.id?'border:2px solid green':''" />
									</b-media-aside>
									<b-media-body class="my-auto">
										<h6 class="mb-0">
											{{ user.name }}
										</h6>
										<small>{{ user.start_time }} - {{ user.end_time }}</small>
									</b-media-body>
								</b-media>
								<div class="d-flex align-items-center">
									<small class="text-muted mr-75"
										:style="duration.id == user.id?'color:green!important':''">{{ user.minutes }}
										min</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</b-card-text>
		<div v-else-if="modalLoading" class="text-center my-2">
			<b-spinner class="d-block mx-auto mb-50"></b-spinner>
			<strong>Loading...</strong>
		</div>
	</b-modal>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCollapse,BFormCheckbox ,VBModal ,BModal,BButton,BCardText,BBadge,BAvatarGroup, BAvatar, VBTooltip,BMediaAside,BMedia,BMediaBody,BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
    BModal,
    BButton,
    BCardText,
    BBadge,
	BAvatarGroup,
	BAvatar,
	BMediaAside,
	BMedia,
	BMediaBody,
	BSpinner
  },
  props: {
	modalLoading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
    },
	channelDetail:{},
    duration:{}
  },
  directives: {
	'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },
  setup(){
	return {
		avatarText
	}
  }
}
</script>
<style>
#channel_users::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}
#channel_users::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

#channel_users::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 100px;
}
#channel_users{
	overflow: auto;
	max-height: 400px;
}
</style>