// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line object-curly-newline
import {ref, computed, watch, onMounted} from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'

export default function useDiscordApi() {
    // Use toast
    const toast = useToast()


    const discordUsers = ref(null)
    const selectDetailUserTeam = ref(null)
    const isLoading = ref(true)
    const refDiscordUsers = ref(null)
    const searchReportDate = ref(moment().format('YYYY-MM-DD'))
    const searchReportDiffMonthDate = ref(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    const searchIntervalDate = ref(null)
    const displayOptions = [{value: 'All', key: -1}, {value: 'Work', key: 1}, {value: 'Fun', key: 0}]
    const overHoursOptions = [{value: 'No', key: 0}, {value: 'Yes', key: 1}]
    const selectDisplay = ref(-1)
    const selectOverHours = ref(0)
    const selectDetailDisplay = ref(-1)
    const selectDetailUser = ref(null)
    const selectDetailOverHours = ref(0)
    const discordUserList = ref(null)
    const teamFilter = ref(null)
    const teamList = ref(null)
    const firstLoad = ref(false)


    // ------------------------------------------------
    // Get Discord Users
    // ------------------------------------------------

    watch([searchReportDate, selectDisplay, selectOverHours, teamFilter], () => {
        isLoading.value = false
        fetchDiscord()
    })

    const fetchDiscord = (ctx, callback) => {
        const start_date = searchReportDate.value ? moment(searchReportDate.value).format('YYYY-MM-DD 08:00') : moment().format('YYYY-MM-DD 08:00')
        const overHoursControl = selectOverHours.value ? '23:59' : '20:00'
        const end_date = searchReportDate.value ? moment(searchReportDate.value).format('YYYY-MM-DD ' + overHoursControl) : moment().format('YYYY-MM-DD ' + overHoursControl)
        isLoading.value = true
        store
            .dispatch('discordStore/fetchDiscord', {
                start_date: start_date,
                end_date: end_date,
                displayType: selectDisplay.value,
                team: teamFilter.value
            })
            .then(response => {
                discordUsers.value = response.data
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Discord User Detail View
    // ------------------------------------------------

    watch([searchIntervalDate, selectDetailDisplay, selectDetailOverHours, selectDetailUser], () => {
        if(selectDetailUser.value !== null){
            isLoading.value = false
            fetchDetailView()
        }
    })

    watch([selectDetailUserTeam], () => {
        const control = discordUsers.value?discordUsers.value.team_id:null
        control !== selectDetailUserTeam.value? selectDetailUser.value = null:selectDetailUser.value
        isLoading.value = false
        fetchDiscordUserList()
    })

    const fetchDetailView = (ctx, callback) => {
        const customdate = searchIntervalDate.value ? searchIntervalDate.value.split('to') : [searchReportDiffMonthDate.value, searchReportDate.value]
        const start_date = customdate[0] ? moment(customdate[0]).format('YYYY-MM-DD 08:00') : moment().format('YYYY-MM-DD 08:00')
        const overHoursControl = selectDetailOverHours.value ? '23:59' : '20:00'
        const end_date = customdate[1] ? moment(customdate[1]).format('YYYY-MM-DD ' + overHoursControl) : moment(customdate[0]).format('YYYY-MM-DD ' + overHoursControl)
        isLoading.value = true
        store
            .dispatch('discordStore/fetchDetailView', {
                id: selectDetailUser.value,
                start_date: start_date,
                end_date: end_date,
                displayType: selectDetailDisplay.value
            })
            .then(response => {
                const {data} = response.data
                discordUsers.value = data
                selectDetailUserTeam.value = data.team_id
                isLoading.value = false
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Discord User List
    // ------------------------------------------------

    const fetchDiscordUserList = (ctx, callback) => {
        store
            .dispatch('discordStore/fetchDiscordUsers', {
                team: selectDetailUserTeam.value
            }).then(response => {
            const {data} = response.data
            discordUserList.value = data.map(item => ({key: item.id, label: item.name, image: item.image}))
        }).catch(error => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                    text: 'Oops! Something went wrong!',
                },
            })
        })
    }

    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeams')
            .then(response => {
                const {data} = response.data
                teamList.value = data.map(item => ({value: item.id, label: item.name}))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }


    return {
        discordUsers,
        fetchDiscord,
        isLoading,
        refDiscordUsers,
        searchReportDate,
        searchIntervalDate,
        displayOptions,
        selectDisplay,
        overHoursOptions,
        selectOverHours,
        fetchDetailView,
        selectDetailOverHours,
        selectDetailDisplay,
        fetchDiscordUserList,
        discordUserList,
        selectDetailUser,
        searchReportDiffMonthDate,
        teamFilter,
        teamList,
        fetchTeamList,
        selectDetailUserTeam,
        firstLoad
    }
}
